@import "../../functions.scss";

.imput-contact {
    width: 100%;
    margin: 20px 0 20px !important;
}

.book_buy_btn {
    @include breakpoint(desktop) {
        position: absolute;
        right:0;
        width: 320px!important;
      }
    @include breakpoint(mobile) {
        margin-left: 0;
        width: 100%;
      }
      @include breakpoint(tablet) {
        margin-left: 0;
        width: 100%;
      }

    padding: 15px 25px 15px !important;
    
    img {
        width: 32px;
        padding: 5px 0 0 12px;
    }

    &:disabled {
        opacity: 0.7;
        cursor: default;
    }
}
.book_clear_btn {
    padding: 15px 25px 15px !important;
    margin-left: 1px!important;
    &:disabled {
        opacity: 0.7;
        cursor: default;
    }
    @include breakpoint(desktop) {

        width: 140px!important;
      }
    @include breakpoint(mobile) {
        margin-left: 0;
        width: 100%;
      }
      @include breakpoint(tablet) {
        margin-left: 0;
        width: 100%;
      }
}
.bool-header-cart{
    margin-top: 20px;
}
.quantity-modifier {
    height: 100%!important;
    width: 20px!important;
    font-size: 20px!important;
    background: #f3f3f3!important;
    color: #888!important;
    border: 0 solid #dbdbdb!important;
    text-align: center!important;
    cursor: pointer!important;
}
.quantity-picker {
    display: inline-block!important;
    border: none!important;
    border-radius: 3px!important;
    vertical-align: middle!important;
}
.quantity-display {
    width: 60px!important;
    padding: 4px!important;
    font-size: 20px!important;
    border: 0!important;
    border-top: 0 solid #dbdbdb!important;
    border-bottom: 0 solid #dbdbdb!important;
    text-align: center!important;
}
.container b {
    font-weight: 600!important;
}
.additional-info{
    margin-bottom: 3px!important;
}
.open_mono{
    margin-left: 1px!important;
}