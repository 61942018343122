@import "./functions.scss";
@import "./fonts.scss";
@import "./html.scss";


.book_popup {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: visibility .35s ease, opacity .35s ease
}

.book__img-bg,
.book__img-book,
.book_popup__close_btn:after,
.book_popup__close_btn:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

a,
b,
body,
code,
div,
form,
html,
img,
label,
s,
section,
tr {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline
}

section {
  display: block
}

:focus {
  outline: 0
}

button::-moz-focus-inner,
input[type=button]::-moz-focus-inner,
input[type=file]>input[type=button]::-moz-focus-inner,
input[type=reset]::-moz-focus-inner,
input[type=submit]::-moz-focus-inner {
  border: none
}

input[type=checkbox]:focus {
  -moz-outline-offset: -1px !important;
  -moz-outline: 1px solid #000 !important
}

::placeholder {
  color: #666
}

*,
::after,
::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.card-staus {
  width: 420px !important;
  
}
.card-body{padding: 50px;}

.imagestatus {
  width: 50px;
  margin: auto;
  padding: 10px 0 20px;
}

body {
  min-width: 360px;
  position: relative;
  overflow-x: hidden;
  font-family: HelveticaNeueCyr, sans-serif;
  font-size: 20px;
  line-height: 1.3;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  color: #000;
  background: #fcfcfc url(https://book.levens.ua/img/site_bg.svg) center top no-repeat
}

button {
  cursor: pointer;
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit;
  font-family: inherit
}

input {
  font-family: inherit
}

a,
button {
  transition: color .35s ease, background .35s ease, border .35s ease
}

a {
  color: inherit;
  text-decoration: none
}

a:hover {
  color: rgb(88, 88, 88);
  text-decoration: none
}

#page-wrap {
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column
}

.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 8px
}

@media (max-width:1599px) {
  .container {
    max-width: 88.8888888889%
  }
}

@media (max-width:1399px) {
  .container {
    max-width: 96%
  }
}

input {
  border: none;
  background-color: transparent;
  padding: 0
}

img {
  display: block;
  height: auto
}

html:not(.no-js) [data-aos=fade-up] {
  -webkit-transform: translate3d(0, 70px, 0);
  transform: translate3d(0, 70px, 0)
}

html:not(.no-js) [data-aos=fade-down] {
  -webkit-transform: translate3d(0, -70px, 0);
  transform: translate3d(0, -70px, 0)
}

.page_title,
.section_title {
  font-family: BerlingskeSerif, serif;
  font-weight: 400;
  line-height: 1.2;
  background: linear-gradient(105.83deg, #000 -3.25%, #707070 78.34%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  display: inline-block
}

.page_title {
  font-size: 48px;
  letter-spacing: -.02em
}

@media (max-width:1599px) {
  .page_title {
    font-size: 42px
  }
}

.section_title {
  font-size: 42px;
  letter-spacing: -.01em
}

@media (max-width:1599px) {
  .section_title {
    font-size: 40px
  }
}

.book {
  font-family: BerlingskeSerif, serif;
  padding: 73px 40px 100px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center
}

@media (max-width:1279px) {
  .book {
    padding: 50px 10px 50px
  }
}

@media (max-width:767px) {
  .book {
    text-align: center
  }
}

.book .page_title {
  margin-top: 103px;
  font-weight: 500;
  font-size: 64px;
  line-height: 1;
  letter-spacing: -.01em
}

@media (max-width:1279px) {
  .book .page_title {
    font-size: 54px;
    margin-top: 75px
  }
}

@media (max-width:1023px) {
  .book .page_title {
    font-size: 56px
  }
}

@media (max-width:767px) {
  .book .page_title {
    margin-top: 10px;
    font-size: 36px
  }
}

@media (max-width:767px) {
  .book__logo img {
    margin-left: auto;
    margin-right: auto
  }
}

@media (max-width:767px) {
  .book__logo {
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translateX(-50%)
  }
}

.book__subtitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 1.4;
  letter-spacing: -.01em;
  background: linear-gradient(105.83deg, #000 -3.25%, #707070 78.34%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-top: 13px
}

@media (max-width:1279px) {
  .book__subtitle {
    font-size: 22px
  }
}

@media (max-width:1023px) {
  .book__subtitle {
    max-width: 350px;
  }
}

@media (min-width:1921px) {
  .bool-header-cart{
    margin-top: 0px!important;
  }
}

@media (max-width:767px) {
  .book__subtitle {
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width:767px) {
  .modal-body {
    h5{
      font-size: 24px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    h3{
      font-size: 32px;
    }
  }
}

.book__note {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -.01em;
  background: linear-gradient(105.83deg, #000 -3.25%, #707070 78.34%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-top: 53px;
  max-width: 600px
}

@media (max-width:1279px) {
  .book__note {
    max-width: 400px;
    margin-top: 35px;
    font-size: 18px
  }
}

@media (max-width:767px) {
  .book__note {
    font-size: 16px
  }
}

.book__buy_btn {
  display: inline-flex;
  justify-content: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: -.01em;
  color: #fff;
  background: linear-gradient(87.5deg, #000 0, #373737 50%, #000 100%);
  background-size: 200% 100%;
  border-radius: 4px;
  padding: 24px 48px;
  margin-top: 40px
}

.book__buy_btn:hover {
  background-position: 100% 0;
  color: #fff
}

@media (max-width:767px) {
  .book__buy_btn {
    font-size: 18px;
    padding: 24px;
    width: 100%
  }
}

.book__row {
  display: flex;
  width: 100%
}

@media (max-width:767px) {
  .book__row {
    flex-direction: column-reverse
  }
}

.book__text {
  width: 100%;
  padding-top: 43px
}

@media (max-width:1023px) {
  .book__text {
    padding-top: 0
  }
}

.book__img {
  position: relative;
  z-index: 1;
  width: 42%;
  mix-blend-mode: darken;
  flex-shrink: 0;
  min-height: 600px
}

@media (max-width:767px) {
  .book__img {
    min-height: 300px;
    width: 100%;
    margin-top: 80px
  }
}

.book__img-book {
  top: 0;
  transform: translate(-50%, 0);
  max-width: 100%
}

@media (max-width:1279px) {
  .book__img-book {
    transform: translate(-50%, -50%);
    width: 300px;
    top: 50%
  }
}

@media (max-width:1023px) {
  .book__img-book {
    width: 275px
  }
}

@media (max-width:767px) {
  .book__img-book {
    width: 200px
  }
}

.book__img-bg {
  z-index: -1
}

@media (max-width:1279px) {
  .book__img-bg {
    width: 700px
  }
}

@media (max-width:1023px) {
  .book__img-bg {
    width: 550px
  }
}

.book_popup {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(245, 244, 244, .47);
  backdrop-filter: blur(64px);
  display: flex;
  padding: 20px;
  overflow-y: auto
}

.book_popup__close_btn {
  width: 30px;
  height: 30px;
  position: absolute;
  left: 100%;
  top: 0;
  z-index: 20;
  margin-left: 31px
}

.book_popup__close_btn:after,
.book_popup__close_btn:before {
  content: '';
  width: 35px;
  height: 1px;
  background-color: #000
}

.book_popup__close_btn:before {
  transform: translate(-50%, -50%) rotate(45deg)
}

.book_popup__close_btn:after {
  transform: translate(-50%, -50%) rotate(-45deg)
}

@media (max-width:1023px) {
  .book_popup__close_btn {
    right: 20px;
    top: 20px;
    margin-left: 0;
    left: auto
  }
}

.book_popup__content {
  background: #fff;
  box-shadow: 0 40px 40px rgba(0, 0, 0, .06);
  border-radius: 8px;
  margin: auto;
  max-width: 850px;
  width: 100%;
  padding: 40px 60px;
  position: relative
}

@media (max-width:1023px) {
  .book_popup__content {
    padding: 40px
  }
}

@media (max-width:767px) {
  .book_popup__content {
    padding: 30px 20px
  }
}

.book_popup__content .section_title {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: -.01em;
  line-height: 1.4
}

@media (max-width:767px) {
  .book_popup__content .section_title {
    font-size: 24px;
    line-height: 1.3
  }
}

.book_popup__form {
  margin-top: 33px
}

.book_popup__form-step {
  padding-left: 20px;
  position: relative
}

.book_popup__form-step+.book_popup__form-step {
  margin-top: 21px
}

.book_popup__form-step__num {
  font-family: BerlingskeSerif, serif;
  position: absolute;
  left: 0;
  top: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: -.01em;
  background: linear-gradient(105.83deg, #000 -3.25%, #707070 78.34%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent
}

.book_popup__form-step__label {
  font-family: BerlingskeSerif, serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: -.01em;
  background: linear-gradient(105.83deg, #000 -3.25%, #707070 78.34%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 17px
}

.book_popup__form-row {
  display: flex;
  align-items: center
}

@media (max-width:767px) {
  .book_popup__form-row {
    flex-wrap: wrap
  }
}

.book_popup__form-input {
  position: relative
}

.book_popup__form-input .copy_btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center
}

.book_popup__form-input input {
  height: 42px;
  border: 1px solid rgba(14, 30, 30, .1);
  border-radius: 8px;
  display: block;
  padding: 0 16px;
  color: #000;
  font-family: BerlingskeSerif, serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: -.01em;
  width: 100%
}

.book_popup__form-note {
  font-family: BerlingskeSerif, serif;
  color: #f55624;
  font-weight: 500;
  font-size: 13px;
  line-height: 1.4;
  letter-spacing: -.01em;
  margin-left: 20px
}

@media (max-width:767px) {
  .book_popup__form-note {
    margin-left: 0;
    margin-top: 10px
  }
}

.book_popup__form-col+.book_popup__form-col {
  margin-left: 16px
}

@media (max-width:767px) {
  .book_popup__form-col+.book_popup__form-col {
    margin-left: 0;
    margin-top: 10px
  }
}

.book_popup__form-col .book_popup__form-input input {
  height: 40px;
  font-size: 14px
}

.book_popup__form .col-tel {
  width: 160px
}

@media (max-width:767px) {
  .book_popup__form .col-tel {
    width: 100%
  }
}

.book_popup__form .col-location,
.book_popup__form .col-name {
  width: 180px
}

@media (max-width:767px) {

  .book_popup__form .col-location,
  .book_popup__form .col-name {
    width: 100%
  }
}

.book_popup__form .col-np {
  width: 120px
}

@media (max-width:767px) {
  .book_popup__form .col-np {
    width: 100%
  }
}

.book_popup__form .card_num {
  width: 252px
}

@media (max-width:767px) {
  .book_popup__form .card_num {
    width: 100%
  }
}

.book_popup__form-label {
  font-family: BerlingskeSerif, serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  letter-spacing: -.01em;
  background: linear-gradient(105.83deg, #000 -3.25%, #707070 78.34%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 3px
}

.book_popup .book__buy_btn {
  margin-top: 24px;
  font-size: 16px;
  font-family: BerlingskeSerif, serif
}

.book_popup--success .section_title {
  text-align: center
}

@keyframes scroll_bar {
  from {
    height: 0
  }

  to {
    height: 100%
  }
}