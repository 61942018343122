@use 'sass:math';

@function getRem($size) {
  $remSize: math.div($size, 16);
  @return #{$remSize}rem;
}

@mixin breakpoint($point) {
  @if $point ==mobile {
    @media screen and (max-width: 576px) {
      @content;
    }
  }

  @else if $point ==mobile_landscape {

    @media screen and (min-width: 667px),
    screen and (min-aspect-ratio: 13/9) {
      @content;
    }
  }

  @else if $point ==tablet {
    @media screen and (min-width: 768px) {
      @content;
    }
  }

  @else if $point ==tablet_landscape {

    @media screen and (min-width: 992px),
    screen and (min-aspect-ratio: 13/9) {
      @content;
    }
  }

  @else if $point ==desktop {
    @media screen and (min-width: 1200px) {
      @content;
    }
  }
}