html {
    font-size: calc(10 * 100vw / 320);
    width: calc(0vw + 100%);
    font-family: "Montserrat-Regular";
  
    @include breakpoint(mobile_landscape) {
      font-size: calc(10 * 100vw / 667);
    }
  
    @include breakpoint(tablet) {
      font-size: calc(12 * 100vw / 768);
    }
  
    @include breakpoint(tablet_landscape) {
      font-size: calc(14 * 100vw / 960);
    }
  
    @include breakpoint(desktop) {
      font-size: calc(16 * 100vw / 1440);
    }
  
    body {
      margin: 0;
      font-family: "BerlingskeSerif";
      overflow-x: hidden !important;
  
      &:before {
        -webkit-transition: .3s all ease;
        -o-transition: .3s all ease;
        transition: .3s all ease;
      }
    }
  }